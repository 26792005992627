import React from "react"
import SEO from "../components/seo"
import HeaderPortfolio from "../components/headerPortfolio";
import MyGallery from "../components/mygallery";
import { gsap } from 'gsap'
import { Link } from "gatsby"
import Layout from "../components/layout"


const Portfolio = () => {
  return(
  <Layout>
    <SEO title="Gyn&eacute;cologie - Obst&eacute;trique - OBGYN - Sant&eacute; - Haiti - Contact" />
    <HeaderPortfolio />
    <div className="pages-body">
      <section className="contact-info color-3">
        <MyGallery />
        <div className="service-cta">
          <Link 
            to="/contact" 
            id="portfolio-cta" 
            className="right color-1 color-0-text cta z-depth-2" 
            onMouseEnter={() => gsap.to("#portfolio-cta", {scale: 1.1, ease: "power4.out"})}
            onMouseLeave={() => gsap.to("#portfolio-cta", {scale: 1, ease: "power4.out"})}
          >
            Contactez-nous!
          </Link>
        </div>
      </section>

    </div>
  </Layout>
)}

export default Portfolio