export const photos = [
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3fpaGGJ8LNiXEIEGCPqlsqgkV8s5hqr-zNErIvAMtI-KS16gkzOtd8pVqza3k0jwZ3Pim3WTwENzO4t6L8-3JQaxKYi2lQalHEGXNoMwhXb6ela3Pg9Qqrn7WkdqB9SDkkwAdBOVO0ciE62yzKELH3r=w400-h300-no?authuser=0",
    width: 4,
    height: 3,
    title: "Ablation gros kyste de l'ovaire"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3ebJ3EHBvfSLtmb8EFqwatDpyaVD4eLmrmVvS0_VqOOSZFIGgs1W_PFMv3rVoiC2FhdsERR_SET8APG9jm3AOKYpyrygpA5fFZ7G6PhXUzF0piSyg7bRYwllVGQ-Hnp7_uqkT828jPACYxPV_6dAd9A=w400-h300-no?authuser=0",
    width: 4,
    height: 3,
    title: "Dr René et Dr Frydman, Pionier de la fecondation in vitro en France"
  },  
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3fshfCIgxjb2ymqesxWctUDzmg_-SFI5vyz6L6jlZwLrDL8DRQE6USW2dKT1yHVgL0inpTasWk7Z4HFux31VaigKhbExSxOitITiHQ1oJtgHsO9F9xZuOhEFaxwarw1xhyo4HoUl7_Gnv0gCAf-d8oM=w400-h300-no?authuser=0",
    width: 4,
    height: 3,
    title: "Dr René et Dr Charles Quist, Formation en Laparoscopie"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3fKOmNiqZ8JeXe0_pngUF_T7r96oHfUkiXv8F-hxao_PntUOuy4WOXaW8gwiuWChQ09tQa6DDtjr4DHzyOttQUs2jgihfzumhVNu3tbmv7OnR1vn5UNVwXIBYE90oGxRgI18fKgtz_VALs-fLDIKFgO=w400-h300-no?authuser=0",
    width: 4,
    height: 3,
    title: "Dr René et Dr Charles Quist, Stage clinique en Fecondation in vitro en Martinique"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3d8TEw46lWOxjS5MXc6LXmVl9PkG277k2k_L736ZLGeVMGDzc4XSTPfvtid7BHLRCrbLvn6AfzFC4MAaLjWzGb1pkWj3LyNzW3RJvmaBRYqgRw7ZYw1kC7Q6i-3yqI-Qa5DQYycVMe0CGnR0XBtCCXc=w400-h300-no?authuser=0",
    width: 4,
    height: 3,
    title: "Dr René et Dr. Zouiten, Stage d'ecographie (sonographie) en Martinique"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3cGvHougl0BXWRMVXg_VqFYBvO3Bsz-g12U2ywJfV6AGXhleQ-ECJYnZnyOBnatvvtjKvJ43py0wvLyqRiCRDwzTxkAtu30MbiuSpVHNu2qLBWsfuWAYUOXVSrX9TVw8jSacdQ9gMTW7YlY1JvvhSQi=w400-h300-no?authuser=0",
    width: 4,
    height: 3,
    title: "Dr René, Hopital Antoine Beclere, Paris, France"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3eXGyzpbOwsEyOzlvqQnfgM0SiUyAUlhETu31dpQGmxUiFgZ3H2A5DjEKATDYA9hY4qElOL65EIuiMw98LcdK04_pt6sypKRg0QAD9lD1ERhO_3OLy2GGnNOmMmTBSWriTmbiYeUeQjoGgM7aIAMREN=w400-h300-no?authuser=0",
    width: 4,
    height: 3,
    title: "Dr René, Cours sur l'infertilite anphiteatre Hopital Antoine Beclere, Paris, France"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3fPa_xs7GqOw7zDOBZRWr1DAmfePRUazDY9Vlw7BDIS-P0v9vKj93cgtlD1_fX_Kz4sCtUdymZ1G4DrB4lHxJZmXhp-TPrE8arSaf6vB2Q_898MRIHiC8yuyKRmH9PAus16qGCSn--UU73-LEpg1045=w400-h300-no?authuser=0",
    width: 4,
    height: 3,
    title: "Dr René, Centre d'assistance medical à la procreation, Paris, France"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3cOQe-itLlzQBAgyZvXFQP_hM14WFSsOZ0vtdUj417BW0HYvvxQPbQvJstW2ErEPOOIbwWX35zIQu5SrYWBi1sH00dBjYGPh14ri4IRmQOTd0VcQvXFEcptnHBleVYzM5iJAnyBxYAznPxNXh5NlJzs=w400-h300-no?authuser=0",
    width: 4,
    height: 3,
    title: "Dr René, Salle d'ecographie 3D, Clamart, France"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3fFnfEBmuffSO5DgWKMSkxgcargiXB7vVfkh6phhd0Jidw88nRNoeFeLG5DDgLJWMJ86sK_j_krS0gzsGVfQT3UWGt41hHg7Zrq65aWVg1WjIMOb4s6a9T4v1mqQxv1-IHynC_Bk_GbmTmjJyOU_orM=w420-h320-no?authuser=0",
    width: 4,
    height: 3,
    title: "Ablation utérus polyfibromateux"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3dE4hLLCe0pMfQzJE-YjVsJ6_iv6pGFB0M3nkCQXQ6EBjoP_t45VkPZpi1ra0L7on0MGDV9ckmOcAlQGPfmGTtpeBp1I-Kf0UlH-IoOjjUdYcGVEGdg8qYRfdP1_oETjqjUb6_1818CJTUaa2sEETYR=w420-h320-no?authuser=0",
    width: 4,
    height: 3,
    title: "Laparotomie d'une masse uterine volumineuse"
  },  
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3c_LEFIrO4n4_46z3JZNhM4q7iPY301Uht_XORqfr79Ppeur7vnKhWKMbSBqWPSuYLnqq7w0c_lKZY1qE62D5Yvk4QYE_4mUWGHkCT5sVSE3IbJVNsE99BPuf-4pMuyxwO-rLvW0FkOxK3IQMiscHN0=w416-h312-no?authuser=0",
    width: 4,
    height: 3,
    title: "9 fibromes enlevés par myomectomie"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3fwGyuSZZmntDfKkPf2LguLk8hT6vfyAEbnb4_sWnc9s0msZ9ELnyBfAKcHF_jQOwdblMuvzy3MbJ1QoUiUUvqk9XLRqqJeLRNlP_N1atQAoiG8XuIHVBRGY2-6pnkJDcou7f_ZTqaolpihNvlt5_Ew=w441-h332-no?authuser=0",
    width: 3,
    height: 3,
    title: "Dr René, Public Health Class, Nova unisersity, Fl, USA"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3coMfCRP0DmYU5LokMPCMLLLNRWFKojzh_o_3lUNKoadY6sX9_Ctgjm2CLGH3lpPyGVvYGv4NZY8C0f9ryrI0wRZSqvByAqOEF9Q1GpYgUsy0qFStxfBf7povTdgxx1ZJThzdC9WuftLgNYx3xvaWp2=w456-h342-no?authuser=0",
    width: 4,
    height: 3,
    title: "Ablation d'un polype du col utérin"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3egXeKmpS-wjGZDm6p8Lz84R0NPHRSA04tIJp-47k0dIMLJjS6SzVen5KM56dLaOG-BlBE0JrNQ-vKJTQCHhvHheD2WQ-cRGQI2VJ8dKoeUXgBLUQJwJjBkQI_-Cg1GjNyW2EHsYoZ2sLF4RynuLMj3=w437-h327-no?authuser=0",
    width: 4,
    height: 3,
    title: "Grossesse multiple, Triplet"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3dHdFYNOx-9i0qDSWDFL-7DDqaIpNbi7zm6zgUvYkasa_KoZTQ38cVKe1hK5DbsveVzkZpQJAhCRlb_CvhqoKv9TivhP28_CidD7pKuBasoAy08tX7VRlki0S2x0J_iV2se1SuioqVXqILUw5AhN2Nv=w411-h308-no?authuser=0",
    width: 4,
    height: 3,
    title: "Vrai noeud du cordon ombilical"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3cqIXjBadbtclChgzTFswEp4FBxPWlLTq2eg3vW3eWggvrI9Hx1qRXBZPiu6MYrVe-7pXkwqt7ekBWa1vLEgX7NEOps_F6qUkJ6Ht9WW2O00UM1sLeVU2T33hl4_x1KRLENNchS69sDZCxYfPh9YnpR=w411-h308-no?authuser=0",
    width: 4,
    height: 3,
    title: "22 fibromes enlevés par myomectomie"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3fmodQU4O3k2Eb4VPOdeBxAu9UA1HviTMBQyYHnJOSJ8-Vz779eXNamTCBxWZu9VCMwNLB8HGkL66qTsQjWgGMtAOdEu7LaSTbJ7pZU9nksHCSa1h8p57Q2YIsishrqB0vtytAqlePKaB2ixfkjz6r4=w423-h317-no?authuser=0",
    width: 4,
    height: 3,
    title: "Ambiguité sexuelle"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3fttv0eli3b2Hg4nCxAhxPrVmUOeA4aYdYNlGUqU6vNusZl7HYxOhWN1PgpbLwKDmOE7viggVJRdHpFCd5vsejkixLd8b9B3UouorR6t3DOnJ_FLdacivIqs5DcVevYJvdFlofFGIjyg0vKqtWH1uf2=w423-h317-no?authuser=0",
    width: 4,
    height: 3,
    title: "Andométriome de l'ombilic, cas rarissime"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3eVeXx3b-djrdWvYKz63XKCnD63OrSNjpHR5M0uzBM-t_vVMtLyh28LOu_NSLB3V2e5YdE2z5z4vkc30G6wvZYAROLglgPJXjMX5CXZq8dc1OyvIFXeI3X-0VAacDNUdp_Mn-Zfy1UZX7JQk8cWhDcp=w423-h317-no?authuser=0",
    width: 4,
    height: 3,
    title: "Ablation d'un kyste dermoide de l'ovaire"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3dJ-rpS0RPx6tCpF54LmFxvTzSe4pw05740PV-W6wFdsW8Pa4n8lIWW6lvlnW0l5hM-UID9b2i9yG3P1-oGrJGi8Hn5O1XI4zQK1zdIwOzH3RDtOcwJFo_HsAu3B8wAQazF7Ylwj8aogLVCYYaJpqno=w400-h300-no?authuser=0",
    width: 4,
    height: 3,
    title: "Fibrome pédicule accouche par le col"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3csqLfvwYs3AwUDyYgNM0fkvegtwPBzUQVuj1bH2PGO3O_1zGSJI38mG1phsL_a8nbwMq2dY0ID_7ZRI6FoV5uWafp8uzm9Oi75Durf9ykItyfqCEZM_oTTeJp0I7rbuEOOnO_FambifBwt651VjSQQ=w400-h300-no?authuser=0",
    width: 4,
    height: 3,
    title: "Kyste de Bartholin gauche"
  },
  {
    src: "https://lh3.googleusercontent.com/pw/ACtC-3eMyWpx9Uo3G_n_-uRfZUY-wl9vmv15_6rbnFBnUF8S_kbR4tJ-Lb9NTh8pv9wv7bqZtOcBgo9ppqGTai_PRWCzFU0qPEF50CjAsGDN6Vhz_O-FUzakLdZVevG7NCPFLzn-rNvC7HZ5tir6vCv8BXiz=w417-h313-no?authuser=0",
    width: 4,
    height: 3,
    title: "Prolapsus uterin grade 4, Exteriorisation totale de l'utérus"
  }
];


